<template>
    <v-card>
        <v-card-title>
            Admin
        </v-card-title>
        <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn id="maak-account-button" to="/admin/maak" style="margin-right: 20px;" outlined class="mobile-block">
                Maak account aan
            </v-btn>
        </v-card-actions>
        <v-card-text>
            <p class="subtitle">
                Met deze accounts kan ingelogt worden op de taxatietool.
            </p>
            <v-data-table :headers="headers" :search="search" :items="gebruikers">
                <template v-slot:top>
                    <v-text-field id="search-bar" class="mx-16" hide-details label="Zoek" v-model="search">
                    </v-text-field>
                </template>
                <template v-slot:item.actions="{ item }">
                    <v-icon name="edit-icon" small class="mr-2" @click="editItem(item)">
                        mdi-pencil
                    </v-icon>
                    <v-icon name="delete-icon" small @click="deleteItem(item)" v-if="!item.master">
                        mdi-delete
                    </v-icon>
                </template></v-data-table
            >
        </v-card-text>
    </v-card>
</template>

<script>
import axios from '../../axios';

export default {
    data() {
        return {
            search: '',
            headers: [
                {
                    text: 'ID',
                    value: '_id',
                    width: 280,
                    sortable: true
                },
                {
                    text: 'Gebruikersnaam',
                    value: 'username'
                },
                {
                    text: 'Taxaties deze week',
                    value: 'taxatiesThisWeekTotal',
                    width: 200
                },
                {
                    text: 'Taxaties deze maand',
                    value: 'taxatiesThisMonthTotal',
                    width: 200
                },
                {
                    text: 'Taxaties totaal',
                    value: 'taxatiesTotal',
                    width: 200
                },
                {
                    text: 'Acties',
                    value: 'actions',
                    width: 120,
                    sortable: false
                }
            ],
            gebruikers: []
        };
    },
    async mounted() {
        this.gebruikers = (await axios.get('/admin')).data;
    },
    methods: {
        editItem(item) {
            this.$router.push(`/admin/maak/${item._id}`);
        },
        async deleteItem(item) {
            const deleteToggle = confirm(
                `Weet je zeker dat je de gebruiker ${this.getGebruiker(item).username} wilt verwijderen?`
            );
            if (deleteToggle) {
                try {
                    await axios.delete(`/admin/${item._id}`);

                    this.gebruikers = (await axios.get('/admin')).data;
                } catch (error) {
                    console.error(error);
                    alert('Er is iets fout gegaan');
                }
            }
        },
        getGebruiker(item) {
            return this.gebruikers.find(gebruiker => gebruiker._id === item._id);
        }
    },
    metaInfo: {
        title: 'Admin'
    }
};
</script>
